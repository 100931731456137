import React from 'react';
import './style.scss';


export default (props) => {
    return (
        <div className={"Columns " + props.className}>
            {props.children}
        </div>
    )
}


