/*
    This is the main site wrapper and contains the site's global styles. 
*/
import React, { Component } from 'react';
// Import Global styles
import '../../assets/scss/styles.scss';
import './style.scss';
import { isDesktop } from '../../helpers.js';
import SEO from '../../components/seo';
import Header from '../../components/Header/Header';
//import HeaderWrap from '../../components/HeaderWrap/HeaderWrap';
import Footer from '../../components/Footer/Footer';

class Wrapper extends Component {

    state = {
        desktop: isDesktop(),
        mobileMenuShow: false
    }

    componentDidMount(){
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = ()  => {
            if (isDesktop() !== this.state.desktop)
            this.setState({desktop: isDesktop()});
    }
   
    mobileMenuToggle = () => {
        console.log('toggle handler');
        this.setState((prevState) => {
            return { mobileMenuShow: !prevState.mobileMenuShow };
        })
    }

    render() {
        return (
            <React.Fragment>
                <SEO title="RAFT" />
                <div className={`wrapper ${this.props.pageCurrent}`}>
                    <Header isDesktop={this.state.desktop} menuToggle={this.mobileMenuToggle} menuShow={this.state.mobileMenuShow} /> 
                    {/* <HeaderWrap isDesktop={this.state.desktop} menuToggle={this.mobileMenuToggle} menuShow={this.state.mobileMenuShow} /> */}
                    {this.props.children}
                    <Footer  footerPattern={this.props.footerPattern} />
                </div>
            </React.Fragment>
        )
    }
}
export default Wrapper;