import React from 'react';
import './style.scss';

const SocialButtons = () => (

    <ul className="social-buttons">
        <li ><a href="https://www.instagram.com/raftcares" target="_blank" rel="noopener noreferrer"><i className="fa fa-instagram"></i></a></li>
        <li><a href="https://www.facebook.com/raftcares" target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook"></i></a></li>
        <li><a href="https://youtube.com"  target="_blank"rel="noopener noreferrer"><i className="fa fa-youtube"></i></a></li>
        <li><a href="https://twitter.com/raftcares" target="_blank" rel="noopener noreferrer"><i className="fa fa-twitter"></i></a></li>
    </ul>

)
export default SocialButtons;