import React from "react"
import Iff from '../../Iff';
import './style.scss';


export default (props) => {
  return ( 
    <React.Fragment>
        <div className={"input-wrapper" + (props.error ? " error" : "")}>
            <Iff condition={props.error}>
                <div className="error-container">
                    <p className="error">{props.message}</p>
                </div>
            </Iff>
            {props.children}
        </div>
    </React.Fragment>
  );
}
