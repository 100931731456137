import React from 'react';
import './style.scss';
import { graphql, StaticQuery, Link } from 'gatsby';
import Search from '../Search/Search';
import SafetyExit from '../SafetyExit/SafetyExit';

const NavMenuDesktop = (props) => (
   <div className="desktop-nav">
        
        <StaticQuery query={graphql`
                {
                    desktopItems: allWordpressWpApiMenusMenusItems(filter: {slug: {eq: "main-menu"}}) {
                        edges {
                            node {
                            id
                            name
                            items {
                                wordpress_id
                                order
                                title
                                url
                                object_id
                                object
                                object_slug
                                type
                                wordpress_children {
                                wordpress_id
                                title
                                url
                                type_label
                                }
                            }
                            }
                        }
                    }
                }
                  
            `
            } render={(data) => {
                //console.log('props menu d ',data);
                return(
                    <React.Fragment >
                        {data &&
                            data.desktopItems &&
                            data.desktopItems.edges &&
                            data.desktopItems.edges[0] &&
                            data.desktopItems.edges[0].node &&
                            data.desktopItems.edges[0].node.items &&
                            data.desktopItems.edges[0].node.items.map(
                                prop => {
                                    if (prop && prop.wordpress_children){
                                        return (
                                            <div className="down"  key={prop.wordpress_id}>
                                                  <div className="name">
                                                      <div className="forgiveness" />
                                                      <div className="triangle" />
                                                      <span>{prop.title}</span>
                                                  </div>
        
                                                  {/* conditionally render directories */}
                                                    {prop && prop.wordpress_children && (
                                                    <div className="down-content">
                                                        <ul className="container">
                                                            { prop.wordpress_children.map(child => {
                                                                //console.log("child ", child)
                                                                return (
                                                                    <li key={child.wordpress_id}>
                                                                        <Link to={child.url} >{child.title}</Link>
                                                                    </li>
                                                                )
                                                            })}
                                                        </ul>
                                                    </div>
                                                    )}
        
        
        
                                            </div>
                                             
                                        )
                                    }
                                    else {
                                        return(
                                                <div className="down" key={prop.wordpress_id}>
                                                        
                                                        <Link className="name" to={`/${prop.title.toLowerCase()}`} ><span>{prop.title}</span></Link>

                                                </div>
                                        )
                                        
                                    }
                                
                                }
                            )}

                             <div className=""> 
                                <Search onToggle={props.onToggle} />
                            </div>
                            <div className="nav-link exit">
                                <SafetyExit />
                            </div>
                    </React.Fragment>
                  
        
            )}} />
   </div>
)
export default NavMenuDesktop;