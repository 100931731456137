export function isDesktop(){
    var viewWidth = function() {
		  if (typeof window !== 'undefined' && typeof document !== 'undefined')
		  	return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		  return 0;
    };
    return viewWidth() > 768;
}


export function htmlDecode(input){
    if (typeof document !== "undefined") {
        var e = document.createElement('div');
        e.innerHTML = input;
        // handle case of empty input
        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    }
    return input;
  }