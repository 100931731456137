import React from 'react';
import { Link } from 'gatsby';
import './style.scss';
import raftLogo from '../../assets/images/raft-logo-v2.png';
import raftLogoInverted from '../../assets/images/raft-logo-inverted.png';

const Logo = (props) => (
    <Link className="logo-link" to="/">
        <img className="logo" src={props.inverted === true ? raftLogoInverted : raftLogo} alt="Resilience for Advocates through Foundational Training" />
    </Link>
)
export default Logo;