import React from 'react';
import './style.scss';
import addToMailchimp from 'gatsby-plugin-mailchimp'
import Iff from '../../Iff';
import InputWrap from '../InputWrap/InputWrap';


class EmailSignup extends React.Component {
    state = {
        NAME: null,
        email: null,
        error: false,
        error_message: null,
        sent: false,
        _holder: "",
    }

    _handleChange = (e) => {
        this.setState({
            [`${e.target.name}`]: e.target.value,
        });
    }

    _handleSubmit = (e) => {
        e.preventDefault();

        if (!this.state.NAME || this.state.NAME.trim().length < 2) {
            this.setState({error: "NAME"});
            return;
        } else if (!this.state.email || this.state.email.trim().length < 2 || !this.state.email.includes('@')) {
            this.setState({error: "email"});
            return;
        }
            
        let fields = {
            NAME: this.state.NAME,
            b_3a991e7ceaddc023dcce212d3_edcfe00a3e: this.state._holder
        };
        if (typeof this.props.groupid !== "undefined")
            fields["group[19685]["+this.props.groupid+"]"] = this.props.groupid;

        console.log("Adding to mailchimp", fields);
        addToMailchimp(this.state.email, fields, 'https://indranislight.us2.list-manage.com/subscribe/post?u=6aea5f2610b63cd56b1f421d7&id=585ba9006a')
            .then(({ msg, result }) => {
                console.log('msg', `${result}: ${msg}`);

                if (result !== 'success') {
                    throw msg;
                }
                this.setState({error: false, sent: true});
            })
            .catch((err) => {
                let message = "Something went wrong when trying to add you to our system.";
                if (err.includes("is already subscribed")) { // Show success message
                    this.setState({error: false, sent:true});
                    return;
                } else if (err.includes("has too many recent signup requests")) {
                    message = "The system is reporting too many recent signup requests on your part – are you a robot?"
                }
                this.setState({error: true, error_message: message});
            });
    }

    render() {
        return (
            <form onSubmit={this._handleSubmit} className="newsletter-signup  base-styles">
                <label>Email Sign Up</label>
                <Iff condition={this.state.error === true}>
                    <p className="error">{this.state.error_message}</p>
                </Iff>
                <Iff condition={!this.state.sent}>
                    <InputWrap error={this.state.error === "NAME"}>
                        <input
                            type="text"
                            onChange={this._handleChange}
                            name="NAME"
                            placeholder="Name"
                        />
                    </InputWrap>
                    
                    <InputWrap error={this.state.error === "email"}>
                        <input
                            type="email"
                            onChange={this._handleChange}
                            name="email"
                            placeholder="Email address"
                        />
                    </InputWrap>
                    {/* provided by mailchimp. this is used to stop bots */}
                    <div style={{'position':'absolute', 'left': '-5000px'}} aria-hidden="true">
                        <input 
                            type="text"
                            name="_holder"
                            tabIndex="-1"
                            value=""
                            onChange={this._handleChange}
                        />
                    </div>
                    <button className="email-submit-btn btn-basic" type="submit" name="submit">Submit <i className="fa fa-long-arrow-right"></i></button>

                </Iff>
                <Iff condition={this.state.sent}>
                    <p>Thank you for signing up!</p>
                </Iff>
            </form>
        );
    }
}
export default EmailSignup;