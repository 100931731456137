import React from 'react';
import BurgerButton from './BurgerButton/BurgerButton';
import MobileMenu from './MobileMenu/MobileMenu';
import SafetyExit from '../SafetyExit/SafetyExit';

const NavMobileMenu = (props) => (

    <React.Fragment>
        <SafetyExit />
        <BurgerButton menuToggle={props.menuToggle} show={props.menuShow} />
        <MobileMenu show={props.menuShow} menuToggle={props.menuToggle} />
    </React.Fragment>

)
export default NavMobileMenu;