import React from 'react';
import './style.scss';

const burgerButton = (props) => (

   /*  <div id="nav-icon3" onClick={props.menuToggle}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
    </div> */
    <div className="btn-wrap" onClick={props.menuToggle} >
        <div id="nav-icon2" className={`btn-burger-menu ${props.show ? 'open' : null}`}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
)
export default burgerButton;