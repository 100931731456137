import React from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';
import './style.scss';
import SocialButtons from './SocialButtons/SocialButtons';
import NewsletterSignUp from '../Forms/EmailSignUp/NewsletterSignUp';
import Columns from '../Columns/Columns';
import Iff from '../Iff';

const Footer = (props) => {
     
    return(
        <React.Fragment>
            <Iff condition={props.footerPattern !== false}>
                <div className="footer-stripes"></div>
             </Iff>
        
        <footer className={`footer `}>
       
        <div className="container">
            <Columns className="content">
            <StaticQuery query={graphql`
                {
                    allWordpressWpApiMenusMenusItems(filter: {slug: {eq: "footer-menu"}}) {
                        edges {
                            node {
                                id
                                name
                                items {
                                    wordpress_id
                                    order
                                    title
                                    url
                                    object_id
                                    object
                                    object_slug
                                    type
                                }
                            }
                        }
                    }
                }
                  
            `} render={ (data) => {
                //const mobileMenu = <div className="li"></div>;
                //console.log('footer datat ',data);
                 const footerMenu = data.allWordpressWpApiMenusMenusItems.edges[0].node.items.map((link) => {
                    
                    return (
                        <li key={link.wordpress_id}><Link to={link.url} >{link.title}</Link></li>
                    )
                    
                }) 

                return (
                    <React.Fragment>
                        <div className="nav">
                            <nav className="footer-menu">
                            
                                <ul className="items">                   
                                    {footerMenu}
                                </ul>
                            </nav>

                        </div>
                              
                            
                        <NewsletterSignUp />
                        <SocialButtons />
                    </React.Fragment>
                )
            }} />

           

                </Columns>
            </div>
       </footer>
       </React.Fragment>
)}
export default Footer;