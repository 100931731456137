import React, {useState, useRef, useEffect} from 'react';
import { navigate } from "gatsby"
import './style.scss';
import Iff from '../Iff';


const Search = (props) => {
    const [open, setOpen] = useState(false);
    const [filterText, changeFilterText] = useState("");
    
    const inputRef = useRef(null)
    const setFocus = () => {inputRef.current &&  inputRef.current.focus()}


    const toggleForm = () => {
        props.onToggle(!open);
        setOpen(!open);
    }

    const search = (event) => {
        event.preventDefault();
        toggleForm();
        navigate("/search/?q=" + filterText);
    }

    useEffect(() => {
        if (open) setFocus();
    });


    return <React.Fragment>
        <Iff condition={!open}>
            <button onClick={toggleForm} className="search"><i className="fa fa-search" /></button>
        </Iff>
        <Iff condition={open}>
            <button onClick={toggleForm} className="search"><i className="fa fa-times" /></button>
            <div className="search-overlay">
                <form onSubmit={search} className="SearchForm">
                    <input type="text" value={filterText} onChange={e=>changeFilterText(e.target.value)} ref={inputRef} placeholder="Search" />
                    <button className="btn-basic" type="submit" name="submit"><i className="fa fa-long-arrow-right" /></button>
                </form>
            </div>
        </Iff>
    </React.Fragment>;

}

export default Search;