import React, {useState, useRef, useEffect} from 'react';
import { graphql, StaticQuery, Link, navigate } from 'gatsby';
import './style.scss';
import Collapsible from 'react-collapsible';
import ArrowIcon from '../../../assets/images/arrow-menu.svg';

const MobileMenu = (props) => {
    //console.log('MobileMenu props ',props);

    const [filterText, changeFilterText] = useState("");
    
    const inputRef = useRef(null)
    const setFocus = () => {inputRef.current &&  inputRef.current.focus()}

    const search = (event) => {
        event.preventDefault();
        navigate("/search/?q=" + filterText);
        props.menuToggle();
    }

    useEffect(() => {
        setFocus();
    });


    return(
        <div className={`mobile-menu-backdrop ${props.show ? 'open' : null }`}>
            <nav>
                <ul className="items">
            <StaticQuery query={graphql`
                {
                    mobileItems: allWordpressWpApiMenusMenusItems(filter: {slug: {eq: "main-menu"}}) {
                    edges {
                      node {
                        id
                        name
                        items {
                          wordpress_id
                          order
                          title
                          url
                          object_id
                          object
                          object_slug
                          type
                          wordpress_children {
                            wordpress_id
                            title
                            url
                            type_label
                            object_slug
                          }
                        }
                      }
                    }
                  }
                }
            `} render={ (data) => {
                //const mobileMenu = <div className="li"></div>;
                 const theMenu = data.mobileItems.edges[0].node.items.map((prop) => {
                     if(prop.wordpress_children){
                         return (
                                
                                <Collapsible
                                        trigger={<span>{prop.title} <ArrowIcon /></span>}
                                        contentContainerTagName="li"
                                        openedClassName="active"
                                        contentInnerClassName="options"
                                        key={prop.wordpress_id}>

                                    {/* conditionally render directories */}
                                        {prop && prop.wordpress_children && (
                                    
                                            <ul className="iner">
                                                { prop.wordpress_children.map(child => {
                                                    //console.log("child ", child)

                                                    return (
                                                        <li key={child.wordpress_id}>
                                                            <Link to={child.url}>{child.title}</Link>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        
                                        )}

                
                            
                                </Collapsible>
                                
                            )
                     }else{
                        return(
                            <Link to={`/${prop.object_slug}`} key={prop.wordpress_id}>{prop.title}</Link>
                        ) 
                     }
                    
                }) 

                return (
                    <React.Fragment>
                         {theMenu}
                         <form onSubmit={search} className="SearchForm">
                            <input type="text" value={filterText} onChange={e=>changeFilterText(e.target.value)} ref={inputRef} placeholder="Search"  />
                            <button className="btn-basic" type="submit" name="submit"><i className="fa fa-long-arrow-right" /></button>
                        </form>
                    </React.Fragment>
                )
            }} />

            </ul>
            </nav>

                

       </div>

)}
export default MobileMenu;