import React, {useState} from 'react';
import './style.scss';
import FeaturedImage from "../../components/FeaturedImage/FeaturedImage"
import Wrapper from '../Wrapper/Wrapper';
import SEO from '../../components/seo';
import PaginatedScroll from '../PaginatedScroll/PaginatedScroll';
import Select from 'react-select';
import { useStaticQuery, graphql } from "gatsby";
import Iff from '../../components/Iff'
import {htmlDecode} from '../../helpers'
import ListItem from '../PaginatedScroll/ListItem/ListItem';
import SoftPagination from '../../components/SoftPagination/SoftPagination';
import Fuse from 'fuse.js'; 

const CommunityBlogHome = ({pageContext}) => {
    // Figure out the categories 
    const allcategories = useStaticQuery(graphql`
        query categoryQuery {
            allWordpressCategory(filter: {wordpress_parent: {eq: 142}}) {
                nodes {
                    wordpress_id
                    count
                    name
                    slug
                }
            }
        }
    `);

    let categories = allcategories.allWordpressCategory.nodes.map((a)=>{return {'value': a.wordpress_id, 'label': htmlDecode(a.name)}});
    categories.unshift({value: false, label: 'Categories'}); // Prepend the default
    const [category, setCategory] = useState(categories[0]);
    
    // Figure out the archives
    const allposts = pageContext.allPosts; 
    let archives = allposts.map(a=>{ return {'value': a.post.archivesort, 'label': a.post.archive} })
    // Remove dupes
    let dupes = {}
    archives = archives.filter(a=>{
        if (!dupes.hasOwnProperty(a.value)) {
            dupes[a.value] = true;
            return true;
        }
        return false;
    });
    // Sort
    archives.sort((a,b) => { return a.value > b.value ? -1 : 1 });

    archives.unshift({value: false, label: 'Archive'}); // Prepend the default
    const [archive, setArchive] = useState(archives[0]);
    
    // Figure out which posts we should show
    const [applicablePosts, setApplicablePosts] = useState(allposts);

    // Handle the filter changes
    const handleCategoryChange = selectedOption => { setCategory(selectedOption); refilter(selectedOption, archive, searched); };
    const handleArchiveChange = selectedOption => { setArchive(selectedOption); refilter(category, selectedOption, searched); };


    const [query, setQuery] = useState("");
    const [searched, setSearched] = useState("");

    const handleQueryChange = (e) => setQuery(e.target.value);
    const search = (e) => {
        e.preventDefault();
        setSearched(query);
        refilter(category, archive, query);
    }


    const refilter = (category, archive, query) => {
        console.log("Looking for ", category.label, archive.value, query);
        let posts = allposts.filter((a)=> {
            const cats = a.post.categories.map(a=>a.wordpress_id);
            if (category.value !== false && !cats.includes(category.value))
                return false;
            if (archive.value !== false && archive.value !== a.post.archivesort)
                return false;
            return true;
        });

        if (query.length > 0) {
            // Search the results
            var fuse = new Fuse(posts, {
                shouldSort: true,
                threshold: 0.1,
                location: 0,
                distance: 10000000,
                maxPatternLength: 32,
                minMatchCharLength: 1,
                keys: [
                "post.title",
                "post.content",
                "post.tags.name",
                "post.categories.name"
                ]
            }); 
            posts = fuse.search(query);
        }
        setApplicablePosts(posts);
    };





    // Put posts into their summary components
    const posts = applicablePosts.map(a=>{return <ListItem item={a}/>});


    const raftgreen = 'rgba(0, 99, 92, 1)';
    const raftdarkgreen = 'rgba(0, 53, 54, 1)';
    const raftlightblue = 'rgba(212, 229, 246, 1)';

    const customStyle = {
        option: (base, state) => ({
            ...base,
            backgroundColor: (state.isSelected ? raftgreen : "#FFFFFF"),
            ':hover': {
                backgroundColor: state.isSelected ? raftdarkgreen : raftlightblue
            }
        }),
        indicatorSeparator: (styles) => ({display: 'none'})
    }


    return (
        <Wrapper pageCurrent={ `community-blog-home ${pageContext.homepage.title.toLowerCase()}` }>
            <SEO title={pageContext.homepage.title} />

            <div className="blockwrap menu-spacer">
                {/* <img className="featured" src={pageContext.thisBlogEdge.featured_media.source_url} alt="featured" /> */}
                <FeaturedImage pageContext={pageContext.homepage} /> 
            </div>
            <div className="blockwrap">
                
                <div className="container base-styles">
                
                    <div className="content">
                        <div className="inner-heading-wrap">
                            <h1 className="animate-underline">{pageContext.homepage.title}</h1>
                        </div>
                        <div className="e" dangerouslySetInnerHTML={{__html: pageContext.homepage.content}} />
                    </div>
                    
                </div>
            
            </div>

            <div className="selectHolder">
                <Select
                    className="Select"
                    value={category}
                    onChange={handleCategoryChange}
                    options={categories}
                    isSearchable={false}
                    styles={customStyle}
                />
                <Select
                    className="Select"
                    value={archive}
                    onChange={handleArchiveChange}
                    options={archives}
                    isSearchable={false}
                    styles={customStyle}
                />
                <form onSubmit={search}>
                    <input type="text" value={query} onChange={handleQueryChange} placeholder="Search" />
                    <button type="submit" name="submit" className="btn-basic"><span>Search </span><i className="fa fa-long-arrow-right" /></button>
                </form>
            </div>
            <div className="blockwrap">
                <Iff condition={category.value === false && archive.value === false && searched.length === 0}>
                    <PaginatedScroll pageContext={pageContext} paginatedType="community-blog" />
                </Iff>
                <Iff condition={category.value !== false || archive.value !== false || searched.length > 0}>
                    <SoftPagination size={10} dataset={category.value + " - " + archive.value} none={<p>No matching posts found.</p>}>
                        {posts}
                    </SoftPagination>
                </Iff>
            </div>
        
        </Wrapper>

    )

}
export default CommunityBlogHome;