import React, {useState} from 'react';
import './style.scss';
import Logo from '../Logo/Logo';
import NavMenuDesktop from '../NavMenuDesktop/NavMenuDesktop';
import NavMenuMobile from '../NavMenuMobile/NavMenuMobile';


const Header = (props) => {
    //console.log('header props ',props);
    //const navMenu = props.isDesktop ? <NavMenuDesktop /> : <NavMobileMenu menuToggle={props.menuToggle} menuShow={props.menuShow} />;

    const [searchOpen, setSearchOpen] = useState(false);

    return (
        <React.Fragment>
           
                <header id="desktop" className={"header blockwrap" + (searchOpen ? " search-open" : "")} >    
                    <div className="inner">
                
                        <div className="side logo-tagline text-left">
                            <Logo inverted={searchOpen} />
                            {/* <h4 className="tagline">Resilience for Advocates through Foundational Training</h4> */}
                        </div>
                        <div className="side navigation">
                        
                            <NavMenuDesktop onToggle={setSearchOpen} />
                        </div>
                    </div>
                </header>
            

                <header id="mobile" className="header blockwrap">  
                    <div className="inner">
                        <div className="side logo-tagline text-left">
                            <Logo />
                        {/*   <h4 className="tagline">Resilience for Advocates through Foundational Training</h4> */}
                        </div>
                        <div className="side navigation">
                        
                            <NavMenuMobile menuToggle={props.menuToggle} menuShow={props.menuShow} />
                        </div>
                    </div>                    
                </header>
            

        </React.Fragment>

    )

}
export default Header;


